<template>
  <div class="highlighted-description">
    <h1>{{ title }}</h1>
    <p>
      <span>
        <slot />
      </span>
    </p>
  </div>

</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
