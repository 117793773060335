<template>
  <v-app>
    <v-main>
      <Header />
      <div>
        <Navbar />
        <router-view />
      </div>
    </v-main>
    <Footer :contact-footer="true" />
  </v-app>
</template>

<script>
import { setupGTag, setupRecaptcha } from '@/utils/tracking.js'
import Header from '@/layout/includes/header.vue'
import Navbar from '@/layout/includes/navbar.vue'
import Footer from '@/layout/includes/footer.vue'

export default {
  components: {
    Header,
    Navbar,
    Footer
  },
  data() {
    return {
      backgroundImage: require('@/assets/images/hero-banner-01.jpg'),
      search: ''
    }
  },
  created() {
    console.log('created')
    setupGTag()
    setupRecaptcha()
  }
}
</script>
