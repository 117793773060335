<template>
  <div class="white">
    <v-row
      id="who_we_are"
      name="who_we_are"
      justify="center"
    >
      <div class="content">
        <p>Pink Zebra is a collective of digital specialists dedicated to disrupting brands that are stuck in the past, and championing those that are embracing a digital future.</p>
        <p>For too long, brands have been stagnant in adoption of new technologies, awaiting competitors to take the first tentative steps by which time, they have already lost their voice in a world of noise. They rely on ‘tried and tested’ methods for communication and engagement that are outdated and approved by those that still remember their landline number.</p>
      </div>
      <v-col
        cols="12"
        order-md="1"
        align-self="center"
        class="img-container"
      >
        <v-img
          :src="require('@/assets/images/phone.png')"
        />
      </v-col>
    </v-row>
    <div style="background-color: #000000;">
      <v-img :src="require('@/assets/images/digital-disruptors-bg.png')" />
    </div>
    <v-container>
      <v-row
        id="what_we_do"
        name="what_we_do"
        class="my-10 fill-height"
        justify="center"
      >
        <v-col
          cols="12"
          md="6"
          align-self="center"
        >
          <HighlightedDescription title="Who we are?">
            Pink Zebra is a collective of <br>digital specialists dedicated <br>to disrupting brands that are <br>stuck in the past, and <br>championing those that are <br>embracing a digital future.
          </HighlightedDescription>
        </v-col>
        <v-col
          cols="12"
          md="6"
          align-self="center"
        >
          <p>
            For too long, brands have been stagnant in adoption of new technologies, awaiting competitors to take the first tentative steps by which time, they have already lost their voice in a world of noise. They rely on ‘tried and tested’ methods for communication and engagement that are outdated and approved by those that still remember their landline number.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <v-img :src="require('@/assets/images/what-we-do-bg.png')" />
    </div>
    <v-container>
      <v-row
        id="myy_app"
        name="myy_app"
        class="my-10"
        justify="center"
      >
        <v-col
          cols="12"
          md="6"
          align-self="center"
        >
          <HighlightedDescription title="What we do">
            Our purpose is to bring brands <br>into a new age of digital <br>connection with its consumers.
          </HighlightedDescription>
        </v-col>
        <v-col
          cols="12"
          md="6"
          align-self="center"
        >
          <p>
            One that breeds loyalty, engagement, conversation and conversion. A system of digital products that are at once universal and local, brand-centric and consumer focused – freed from the shackles of archaic thought processes that have been driven by habits developed decades ago, and which thrive on utilising our experience of the then, our understanding of the now and predictions of a better, digital future.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <!-- <carousel /> -->
  </div>
</template>

<script>
import HighlightedDescription from '../components/HighlightedDescription.vue'
// import carousel from '@/components/carousel.vue'

export default {
  name: 'Home',
  components: {
    HighlightedDescription
    // carousel //temporary hidden
  }
}
</script>
