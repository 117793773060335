<template>
  <div class="header-container">
    <v-app-bar
      absolute
      flat
      style="background-color: #000000; height: auto"
      prominent
      class="header"
    >
      <div class="left-container">
        <img
          class="company-name-logo"
          :src="require('@/assets/images/company-pz.png')"
          style="max-width: 100px"
        >
      </div>
      <v-spacer />
      <div class="d-flex flex-row right-container">
        <img
          :src="require('@/assets/images/logo-pz.png')"
          style="max-width: 100px"
        >
        <v-icon dark class="burger-menu" @click="handleMenu">
          mdi-menu
        </v-icon>
      </div>
    </v-app-bar>
    <v-dialog
      v-model="isOpen"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <div class="mobile-toolbar">
          <v-btn
            icon
            @click="handleMenu"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="mobile-menu">
          <ul>
            <li v-for="(menu, index) in menus" :key="index">{{ menu.name }}</li>
          </ul>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { menuData } from '../../data/menuData'

export default {
  name: 'Navbar',
  data() {
    return {
      isOpen: false,
      menus: menuData
    }
  },
  methods: {
    handleMenu() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>
