<template>
  <div class="navbar-container">
    <ul class="primary--text">
      <li v-for="(menu, index) in menus" :key="index">{{ menu.name }}</li>
    </ul>
  </div>
</template>

<script>
import { menuData } from '../../data/menuData'

export default {
  data() {
    return {
      menus: menuData
    }
  }
}
</script>
